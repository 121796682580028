import * as React from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const WarnIcon = props => _jsxs("svg", {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  ...props,
  children: [_jsx("path", {
    d: "M0 0h24v24H0z",
    fill: "none"
  }), _jsx("path", {
    d: "M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
  })]
});
export default WarnIcon;