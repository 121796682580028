import * as React from "react";
import * as Intl from "react-intl";
import { jsx as _jsx } from "react/jsx-runtime";
export default function I18n({
  widget,
  lang: _lang,
  children
}) {
  const [messages, setMessages] = React.useState();
  const [lang, setLang] = React.useState(_lang);
  React.useEffect(() => {
    const fetchMessages = async () => {
      const {
        messages,
        lang: newLang
      } = await loadMessages(widget, _lang);
      setMessages(messages);
      setLang(newLang);
    };
    fetchMessages();
  }, [widget, _lang]);
  if (!messages) return null;
  return _jsx(Intl.IntlProvider, {
    messages: messages,
    locale: lang,
    children: children
  });
}
async function fetchLocale(widgetName, lang) {
  const response = await fetch(`${import.meta.env.VITE_PUBLIC_URL}/locales/${widgetName}/${lang}.json`);
  const messages = await response.json();
  return messages;
}
async function loadMessages(widgetName, lang) {
  try {
    const messages = await fetchLocale(widgetName, lang);
    return {
      messages,
      lang
    };
  } catch (e) {
    const messages = await fetchLocale(widgetName, "en");
    return {
      messages,
      lang: "en"
    };
  }
}