export function generatePalettes(force = false) {
    const root = document.documentElement;
    const cs = getComputedStyle(root);
    if (variableExists(cs, "--msem-color-primary")) {
        generatePalette(cs, "--msem-color-primary", force);
        generatePalette(cs, "--msem-color-secondary", force);
        generateBackdrop(cs, "--msem-color-medium", 0.25, force);
        generateBackdrop(cs, "--msem-color-darkest", 0.9, force);
        generateBackdrop(cs, "--msem-color-darkest", 0.9, force);
        generateGradient(cs, "--msem-color-light", "--msem-color-mediumlight", force);
        return;
    }
    requestAnimationFrame(() => generatePalettes(force));
}
function generatePalette(cs, variable, force = false) {
    const color = parseColor(`var(${variable})`);
    if (!color)
        return;
    const hsl = RGBToHSL(color);
    setProperty(cs, `${variable}-light`, HSLColorAddToLightness(hsl, 15), force);
    setProperty(cs, `${variable}-lighter`, HSLColorAddToLightness(hsl, 30), force);
    setProperty(cs, `${variable}-lightest`, HSLColorAddToLightness(hsl, 55), force);
    setProperty(cs, `${variable}-dark`, HSLColorAddToLightness(hsl, -5), force);
    setProperty(cs, `${variable}-darker`, HSLColorAddToLightness(hsl, -15), force);
    setProperty(cs, `${variable}-darkest`, HSLColorAddToLightness(hsl, -25), force);
    const gradientToDark = hsl[2] >= 40;
    setProperty(cs, `${variable}-gradient`, computeGradient(variable, gradientToDark), force);
    setProperty(cs, `${variable}-gradient-light`, computeGradient(variable, false), force);
    setProperty(cs, `${variable}-gradient-dark`, computeGradient(variable, true), force);
}
function generateGradient(cs, variable, variable2, force = false) {
    const color = parseColor(`var(${variable})`);
    const color2 = parseColor(`var(${variable2})`);
    if (!color || !color2)
        return;
    setProperty(cs, `${variable}-gradient`, computeGradientVariant(variable, variable2), force);
}
function generateBackdrop(cs, variable, alpha, force = false) {
    const color = parseColor(`var(${variable})`);
    if (!color)
        return;
    const hsl = RGBToHSL(color);
    setProperty(cs, `${variable}-light`, HSLColorAddToLightness(hsl, 15), force);
    setProperty(cs, `${variable}-backdrop`, HSLColorAddToLightness(hsl, 0, alpha), force);
}
function setProperty(cs, variable, color, force = false) {
    if (color && (!variableExists(cs, variable) || force)) {
        document.documentElement.style.setProperty(variable, color);
    }
}
function variableExists(cs, variable) {
    const light = cs.getPropertyValue(variable);
    return light.length > 0;
}
function HSLColorAddToLightness(hsl, lightnessDelta, alpha = 1) {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2] + lightnessDelta}%, ${alpha})`;
}
function computeGradient(variable, gradientToDark) {
    const to = gradientToDark ? "dark" : "light";
    return `linear-gradient(135deg, var(${variable}), var(${variable}-${to}))`;
}
function computeGradientVariant(variable, variable2) {
    return `linear-gradient(135deg, var(${variable}), var(${variable2}))`;
}
function parseColor(color) {
    const d = document.createElement("div");
    d.style.color = color;
    document.body.appendChild(d);
    const c = getComputedStyle(d).color;
    document.body.removeChild(d);
    const m = c.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);
    if (m) {
        return [Number(m[1]), Number(m[2]), Number(m[3])];
    }
}
function RGBToHSL(rgb) {
    let r = rgb[0];
    let g = rgb[1];
    let b = rgb[2];
    r /= 255;
    g /= 255;
    b /= 255;
    var max = Math.max(r, g, b);
    var min = Math.min(r, g, b);
    var c = max - min;
    var lum = (max + min) / 2;
    var hue = 0;
    var sat = 0;
    if (c == 0) {
        hue = 0;
    }
    else {
        sat = c / (1 - Math.abs(2 * lum - 1));
        switch (max) {
            case r:
                var segment = (g - b) / c;
                var shift = 0 / 60; // R° / (360° / hex sides)
                if (segment < 0) {
                    // hue > 180, full rotation
                    shift = 360 / 60; // R° / (360° / hex sides)
                }
                hue = segment + shift;
                break;
            case g:
                var segment = (b - r) / c;
                var shift = 120 / 60; // G° / (360° / hex sides)
                hue = segment + shift;
                break;
            case b:
                var segment = (r - g) / c;
                var shift = 240 / 60; // B° / (360° / hex sides)
                hue = segment + shift;
                break;
        }
    }
    return [hue * 60, sat * 100, lum * 100]; // hue is in [0,6], scale it up
}
