import * as React from "react";

type Props = {
  grounded: boolean;
  verticalMargin: number;
  minHeight?: number;
};

export default function useAvailableHeight({ grounded = false, verticalMargin = 0, minHeight }: Props) {
  const [height, setHeight] = React.useState<number>();
  React.useEffect(() => {
    const resized = () => {
      const { innerWidth, innerHeight } = window;
      setHeight(innerWidth <= 720 ? innerHeight : Math.max(innerHeight - verticalMargin, 320));
    };
    if (!grounded) {
      resized();
      window.addEventListener("resize", resized);
      return () => window.removeEventListener("resize", resized);
    } else if (minHeight) {
      setHeight(minHeight);
    }
  }, [minHeight, grounded, verticalMargin]);

  return height || "auto";
}
