import * as React from "react";
import * as Intl from "react-intl";
import PaxPlanForm from "./pax-plan-form";
import * as Stay from "../../services/stay";
import WarnIcon from "msem-lib/es/icons/warn";
import css from "./paxplan-picker.module.css";
import I18n from "msem-lib/es/components/i18n";

type Paxplan = {
  adults: number;
  children: number;
  agesChildren: number[];
};

type Props = {
  close: () => void;
  paxplanChanged: (paxplan: Paxplan) => void;
  paxplan: Paxplan;
};

export default function PaxplanPicker({ close, paxplanChanged, paxplan }: Props) {
  const intl = Intl.useIntl();
  const [pax, setPax] = React.useState(paxplan);

  const validated = (paxplan: Paxplan) => () => {
    setPax(paxplan);
    paxplanChanged(paxplan);
    close();
  };

  const warning = Stay.readPaxplan() && Stay.readCartId() ? intl.formatMessage({ id: "warningPaxplan" }) : undefined;
  const currentLang = intl.locale || "fr";

  return (
    <>
      {warning && (
        <div className={css.warning}>
          <div className={css.warningSign}>
            <WarnIcon />
          </div>
          <span className={css.warningLabel}>{warning}</span>
        </div>
      )}
      <div className={css.wrapper}>
        <I18n lang={currentLang} widget={"msem-lodging"}>
          <PaxPlanForm onValidate={validated} compact={false} {...pax} />
        </I18n>
      </div>
    </>
  );
}
