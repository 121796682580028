import * as React from "react";
import css from "./loader.module.css";
import { jsx as _jsx } from "react/jsx-runtime";
const Loader = ({
  size = 24
}) => {
  const diagonal = Math.sqrt(2 * Math.pow(size, 2));
  const loaderStyle = {
    width: size,
    height: size
  };
  const outerStyle = {
    height: diagonal
  };
  return _jsx("div", {
    className: css.loaderOuter,
    style: outerStyle,
    children: _jsx("div", {
      className: css.loader,
      style: loaderStyle,
      children: _jsx("svg", {
        viewBox: "22 22 44 44",
        children: _jsx("circle", {
          className: css.circle,
          cx: "44",
          cy: "44",
          r: "18.4",
          fill: "none",
          strokeWidth: "3.6"
        })
      })
    })
  });
};
export default Loader;