export function formatDate(date: Date) {
  const y = date.getFullYear();
  const m = String(date.getMonth() + 1).padStart(2, "0");
  const d = String(date.getDate()).padStart(2, "0");
  return `${y}-${m}-${d}`;
}

export function dateEqual(a: Date, b: Date) {
  return formatDate(a) === formatDate(b);
}

type Range = { from: Date; to: Date };
export function datesEqual(a: Range, b: Range) {
  return dateEqual(a.from, b.from) && dateEqual(a.to, b.to);
}
