import * as React from "react";
export const defaultScreenSizes = {
  XS: true,
  SM: false,
  MD: false,
  LG: false,
  XL: false,
  XXL: false
};
const defaultValue = {
  options: {},
  presets: {},
  screenSizes: defaultScreenSizes
};
export const WidgetContext = React.createContext(defaultValue);
WidgetContext.displayName = "WidgetContext";
export default function useWidgetContext() {
  return React.useContext(WidgetContext);
}