import * as Pkce from "./pkce";
import * as QueryString from "./querystring";
import configuration from "../lib/config";
const redirectUri = () => {
    return window.location.origin + window.location.pathname + window.location.hash;
};
export const exchangeCodeForAccessToken = async (code, code_verifier) => {
    const config = configuration();
    const url = `${config.AUTH_ORIGIN}/api/oauth/token`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: QueryString.stringify({
            grant_type: "authorization_code",
            client_id: config.CLIENT_ID,
            redirect_uri: redirectUri(),
            code,
            code_verifier,
        }),
    });
    if (response.status !== 200) {
        throw new Error(await response.text());
    }
    return response.json();
};
export const exchangeRefreshForAccessToken = async (token) => {
    const config = configuration();
    const url = `${config.AUTH_ORIGIN}/api/oauth/token`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: QueryString.stringify({
            grant_type: "refresh_token",
            client_id: config.CLIENT_ID,
            refresh_token: token.refresh_token,
        }),
    });
    if (response.status !== 200) {
        return undefined;
    }
    return response.json();
};
export const revokeToken = async (token) => {
    const config = configuration();
    const { token_type, access_token } = token;
    const url = `${config.AUTH_ORIGIN}/api/oauth/revoke-token`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `${token_type} ${access_token}`,
        },
        body: QueryString.stringify({
            client_id: config.CLIENT_ID,
        }),
    });
    if (response.status !== 200) {
        throw new Error(await response.text());
    }
    return response.json();
};
export const signIn = async (channel, lang, signup = false) => {
    const config = configuration();
    const state = Pkce.generateRandomString();
    const code_verifier = Pkce.generateRandomString();
    const code_challenge = await Pkce.challengeFromVerifier(code_verifier);
    const params = QueryString.stringify({
        response_type: "code",
        scope: config.SCOPE,
        client_id: config.CLIENT_ID,
        redirect_uri: redirectUri(),
        state,
        channel,
        lang,
        code_challenge,
        code_challenge_method: "S256",
        signup,
    });
    sessionStorage.setItem("pkce_code_verifier", code_verifier);
    sessionStorage.setItem("pkce_state", state);
    const url = `${config.AUTH_ORIGIN}/api/oauth/authorize`;
    window.location.href = `${url}?${params}` + window.location.hash;
};
export const signOut = async (channel, lang) => {
    const config = configuration();
    const params = QueryString.stringify({
        redirect_uri: redirectUri(),
        client_id: config.CLIENT_ID,
        channel,
        lang,
    });
    const url = `${config.AUTH_ORIGIN}/api/identification/logout`;
    window.location.href = `${url}?${params}` + window.location.hash;
};
export const signUp = async (channel, lang) => {
    return signIn(channel, lang, true);
};
