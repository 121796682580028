import * as React from "react";
import * as ReactDOM from "react-dom/client";
import * as ReactQuery from "react-query";
import Loader from "msem-lib/es/components/loader";
import * as Theme from "msem-ui/es/theme";
import Widget from "./components/widget/widget";
import * as Facet from "./services/facet";

import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/fr";
import "@formatjs/intl-pluralrules/locale-data/en";
import { refreshToken } from "./services/token";

let tunnelRef;

const THEME_ID = "msem-theme";
const DEFAULT_THEME = `${import.meta.env.VITE_PUBLIC_URL}/themes/MseM.css`;
const DEFAULT_WIDGET_CONFIG = {
  refreshOnLodgingChange: false,
};
const queryClient = new ReactQuery.QueryClient({
  defaultOptions: {
    queries: { suspense: true, staleTime: 1000 * 60 * 60 },
  },
});

const injectTheme = (url = DEFAULT_THEME) => {
  const theme = document.getElementById(THEME_ID);
  if (theme) return;
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = url;
  link.id = THEME_ID;
  link.onload = Theme.generatePalettes;
  document.head.appendChild(link);
};

const createParent = (parentId) => {
  const el = document.createElement("div");
  el.id = parentId;
  if (parentId === "MseM-tunnel") {
    document.body.insertBefore(el, document.body.firstChild);
  } else {
    document.body.appendChild(el);
  }
  return el;
};

const findParent = (parentId, options) => {
  const { groundedTo } = options;
  const targetSelector = groundedTo ?? `#${parentId}`;
  const target = document.querySelector(targetSelector);
  return target ?? createParent(parentId);
};

const readFromUrl = (name) => {
  const params = new URL(document.location.href).searchParams;
  const value = params.get(name);
  return value || undefined;
};

const optionsPatch = (name, value) => {
  return value !== undefined ? { [name]: value } : {};
};

const stringFromUrl = (name) => {
  const param = readFromUrl(name);
  return optionsPatch(name, param);
};

const numberFromUrl = (name) => {
  const param = readFromUrl(name);
  const value = param ? Number(param) : undefined;
  return optionsPatch(name, value);
};

const langFromUrl = () => {
  const lang = readFromUrl("lang");
  const language = readFromUrl("language");
  const result = lang || language;
  return result !== undefined ? { lang: result.toLowerCase().indexOf("fr") === 0 ? "fr" : "en" } : {};
};

const booleanFromUrl = (name) => {
  const param = readFromUrl(name);
  const value = param ? param === "true" : undefined;
  return optionsPatch(name, value);
};

const setBodyOverflow = (value) => {
  document.body.style.overflow = value;
};

const open = async (widget, parentId, options, presets = {}, config = DEFAULT_WIDGET_CONFIG) => {
  const container = findParent(parentId, options);

  await refreshToken();

  const floating = options.groundedTo === undefined;
  const KindWidget = widget;
  const computedFacet = Facet.computeFacetBySwitchDate(options, presets?.stay?.from);
  const overloadedFacet = computedFacet !== undefined ? computedFacet : options.facet || 0;
  const paramsOption = {
    ...options,
    lang: options.lang === "fr" || options.lang === undefined ? "fr" : "en",
    facet: overloadedFacet,
  };
  const overridenOptions = {
    ...paramsOption,
    ...langFromUrl(),
    ...numberFromUrl("facet"),
    ...numberFromUrl("hoteid"),
    ...numberFromUrl("resort"),
    ...stringFromUrl("channel"),
    ...booleanFromUrl("preview"),
    ...booleanFromUrl("debugXML"),
    ...stringFromUrl("partenaire"),
    ...booleanFromUrl("isGescoOperator"),
    ...booleanFromUrl("prodGateway"),
    ...stringFromUrl("cartId"),
  };

  injectTheme(options.theme);

  if (floating && !options.draggable) {
    setBodyOverflow("hidden");
  }

  const refreshTunnel = () => {
    if (tunnelRef) {
      const [buildTunnelTree, , , tunnelRoot] = tunnelRef;
      tunnelRoot.render(buildTunnelTree());
    }
  };

  const close = () => {
    if (!tunnelRef && floating) {
      setBodyOverflow("");
    } else {
      const [, tunnelContainer, tunnelFloating] = tunnelRef;
      const isTunnel = tunnelContainer === container;
      if (floating && (isTunnel || !tunnelFloating)) {
        setBodyOverflow("");
      }
      if (isTunnel) {
        tunnelRef = undefined;
      } else {
        refreshTunnel();
      }
    }
    root.unmount();
    delete container.reactRoot;

    // Remove hash from URL
    const { pathname, search } = window.location;
    window.history.pushState("", document.title, pathname + search);
  };

  const buildTree = () => {
    const timestamp = new Date().getTime();
    return (
      <React.StrictMode>
        <ReactQuery.QueryClientProvider client={queryClient}>
          <Widget
            key={timestamp}
            options={overridenOptions}
            presets={presets}
            refreshTunnel={refreshTunnel}
            close={floating ? close : undefined}
            config={config}
          >
            <React.Suspense fallback={<Loader />}>
              <KindWidget options={overridenOptions} presets={presets} />
            </React.Suspense>
          </Widget>
        </ReactQuery.QueryClientProvider>
      </React.StrictMode>
    );
  };

  // BEGIN : Attention : Hack à conserver pour éviter les multiples render de widgets dans les sites marchands
  let root = container.reactRoot ?? ReactDOM.createRoot(container);
  container.reactRoot = root;

  try {
    root.render(buildTree());
  } catch (e) {
    root = ReactDOM.createRoot(container);
    container.reactRoot = root;
    root.render(buildTree());
  }
  // END : Attention : Hack à conserver pour éviter les multiples render de widgets dans les sites marchands

  return [buildTree, container, floating, root];
};

const lodging = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/lodging-widget")),
    "MseM-lodging",
    options,
    presets,
    { keyExclusions: ["stay"] }
  );
};

const skiRental = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/gear-rental-widget")),
    "MseM-gearRental",
    options,
    presets,
    { keyExclusions: ["stay"] }
  );
};

const skiPass = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/ski-pass-widget")),
    "MseM-skiPass",
    options,
    presets
  );
};

const esfOld = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/esf-widget-old")),
    "MseM-esf-old",
    options,
    presets
  );
};

const esf = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/esf-widget")),
    "MseM-esf",
    options,
    presets
  );
};

const tunnel = async (options, presets) => {
  tunnelRef = await open(
    React.lazy(() => import("./widgets/tunnel-widget")),
    "MseM-tunnel",
    options,
    presets
  );
};

const otherProducts = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/other-products-widget")),
    "MseM-otherProducts",
    options,
    presets,
    { refreshOnLodgingChange: true }
  );
};

const transfer = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/transfer-widget")),
    "MseM-transfer",
    { ...options, mode: "PRIVATE_TRANSFER" },
    presets
  );
};

const excursion = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/transfer-widget")),
    "MseM-transfer",
    { ...options, mode: "EXCURSION" },
    presets
  );
};

const shuttle = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/transfer-widget")),
    "MseM-transfer",
    { ...options, mode: "SHUTTLE" },
    presets
  );
};

const genericLine = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/generic-line-widget")),
    "MseM-genericLine",
    options,
    presets
  );
};

const esfCustom = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/esf-custom-widget")),
    "MseM-esfCustom",
    options,
    presets
  );
};

const vakario = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/vakario-widget")),
    "MseM-vakario",
    options,
    presets
  );
};

const elloha = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/elloha-activity-widget")),
    "MseM-elloha",
    options,
    presets
  );
};

const customerAccount = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/customer-account-widget")),
    "MseM-customer-account",
    options,
    presets
  );
};

const newCustomerAccount = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/new-customer-account-widget")),
    "MseM-new-customer-account",
    options,
    presets
  );
};

const liftJb = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/lift-jb-widget")),
    "MseM-lift-jb",
    options,
    presets
  );
};

const contact = (options, presets) => {
  open(
    React.lazy(() => import("./widgets/contact-widget")),
    "MseM-contact",
    options,
    presets
  );
};

window.MseM = {
  ...window.MseM,
  lodging,
  skiRental,
  skiPass,
  esfOld,
  esf,
  tunnel,
  otherProducts,
  transfer,
  excursion,
  shuttle,
  genericLine,
  esfCustom,
  vakario,
  elloha,
  customerAccount,
  newCustomerAccount,
  liftJb,
  contact,
};
