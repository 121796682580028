import * as React from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const ChevronLeftIcon = props => _jsxs("svg", {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  ...props,
  children: [_jsx("path", {
    d: "M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
  }), _jsx("path", {
    d: "M0 0h24v24H0z",
    fill: "none"
  })]
});
export default ChevronLeftIcon;