import * as React from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const ChevronRightIcon = props => _jsxs("svg", {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  ...props,
  children: [_jsx("path", {
    d: "M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
  }), _jsx("path", {
    d: "M0 0h24v24H0z",
    fill: "none"
  })]
});
export default ChevronRightIcon;