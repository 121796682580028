import * as React from "react";
import classnames from "classnames";
import ChevronLeft from "../icons/chevron-left";
import ChevronRight from "../icons/chevron-right";
import css from "./integer-picker.module.css";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const subtract = (value, min, max, onChange) => () => {
  changeValue(value, -1, min, max, onChange);
};
const add = (value, min, max, onChange) => () => {
  changeValue(value, 1, min, max, onChange);
};
const changeValue = (value, step, min, max, onChange) => {
  const newValue = value + step;
  if (newValue >= min && newValue <= max) {
    onChange(newValue);
  }
};
const isBelow = (val, min, disabled) => disabled || val <= min;
const isAbove = (val, max, disabled) => disabled || val >= max;
const IntegerPicker = ({
  min,
  max,
  value,
  disabled = false,
  onChange
}) => {
  const labelClasses = classnames(css.label, {
    [css.selected]: value > 0
  });
  return _jsxs("div", {
    className: css.picker,
    children: [_jsx("a", {
      className: css.button,
      onClick: e => {
        e.stopPropagation();
        subtract(value, min, max, onChange)();
      },
      disabled: isBelow(value, min, disabled),
      tabIndex: 0,
      children: _jsx(ChevronLeft, {
        className: css.icon
      })
    }), _jsx("span", {
      className: labelClasses,
      children: value
    }), _jsx("a", {
      className: css.button,
      onClick: e => {
        e.stopPropagation();
        add(value, min, max, onChange)();
      },
      disabled: isAbove(value, max, disabled),
      tabIndex: 0,
      children: _jsx(ChevronRight, {
        className: css.icon
      })
    })]
  });
};
export default IntegerPicker;