const ENV = import.meta.env.VITE_APP_ENV;
const isLocal = () => {
  return ENV === undefined;
};
const isWorkBranch = () => {
  return ENV === "";
};
const isDeployBranch = () => {
  return !isLocal() && !isWorkBranch();
};
const defaultTarget = () => {
  return isLocal() ? "development" : "integration";
};
const isBrowser = () => {
  return typeof window !== undefined && window.MseM !== undefined;
};
export const target = () => {
  if (isDeployBranch()) return ENV;
  const forcedTarget = isBrowser() && window.sessionStorage.getItem("MseM-environment");
  return forcedTarget || defaultTarget();
};
const workEnvs = ["integration", "uat", "production"];
const localEnvs = ["development", ...workEnvs];
export const targets = () => {
  if (isWorkBranch()) return workEnvs;
  if (isLocal()) return localEnvs;
  return [ENV];
};