const toHex = (dec) => Number(dec).toString(16).padStart(2, "0");
const sha256 = (plain) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest("SHA-256", data);
};
const base64urlencode = (str) => {
    const buffer = new Uint8Array(str);
    const array = Array.from(buffer);
    return btoa(String.fromCharCode.apply(null, array))
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
};
export const generateRandomString = () => {
    const buffer = new Uint32Array(28);
    window.crypto.getRandomValues(buffer);
    return Array.from(buffer, toHex).join("");
};
export const challengeFromVerifier = async (verifier) => {
    const hashed = await sha256(verifier);
    return base64urlencode(hashed);
};
