import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from "classnames";
import * as React from "react";
import * as Ripple from "./ripple";
import Spinner from "./spinner";
import css from "./button.module.css";
const Button = React.forwardRef(({ tag = "button", variant = "primary", size = "M", className, onMouseDown, onKeyDown, iconLeft, iconRight, iconClassName, isLoading, notFocusable = false, disabled = false, children, stopPropagation = true, ...props }, ref) => {
    const down = React.useRef(false);
    const classes = classnames(css.button, {
        [css.sizeS]: size === "S",
        [css.primary]: variant === "primary",
        [css.secondary]: variant === "secondary",
        [css.tertiary]: variant === "tertiary",
        [css.isLoading]: isLoading,
        [css.notFocusable]: notFocusable,
    }, className);
    const classesLoader = classnames(css.loader, {
        [css.loaderSecondary]: variant === "secondary",
    });
    const mousedowned = (e) => {
        if (stopPropagation)
            e.stopPropagation();
        Ripple.create(e, "var(--color-back)");
    };
    const keydowned = (e) => {
        if (stopPropagation)
            e.stopPropagation();
        if (!down.current && (e.code === "Space" || e.code === "Enter")) {
            Ripple.create(e, "var(--color-back)");
        }
        down.current = true;
    };
    const keyup = (e) => {
        e.stopPropagation();
        down.current = false;
    };
    const Tag = tag;
    return (_jsxs(Tag, { ...props, disabled: disabled || isLoading, tabIndex: tag === "a" ? 0 : notFocusable ? -1 : undefined, className: classes, onMouseDown: mousedowned, onKeyDown: keydowned, onKeyUp: keyup, 
        // @ts-ignore
        ref: ref, children: [iconLeft && (_jsx("span", { className: classnames(css.iconLeft, iconClassName), children: isLoading ? _jsx(Spinner, { className: classesLoader, size: size, variant: variant }) : iconLeft })), !iconRight && !iconLeft && isLoading ? (_jsx("span", { children: isLoading && _jsx(Spinner, { className: classesLoader, size: size, variant: variant }) })) : (children), iconRight && (_jsx("span", { className: classnames(css.iconRight, iconClassName), children: isLoading ? _jsx(Spinner, { className: classesLoader, size: size, variant: variant }) : iconRight }))] }));
});
export default Button;
