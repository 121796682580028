import { exchangeRefreshForAccessToken } from "msem-customer/es/auth/oauth";

const ONE_HOUR = 60 * 60 * 1000;
const TOKEN = "msem_authorization_token";

export async function refreshToken() {
  const stored = localStorage.getItem(TOKEN);
  if (!stored) {
    return;
  }
  const token = JSON.parse(stored);
  if (!isExpiring(token.expires)) {
    return;
  }
  const newToken = await exchangeRefreshForAccessToken(token);
  if (newToken) {
    localStorage.setItem(TOKEN, JSON.stringify(newToken));
  } else {
    localStorage.removeItem(TOKEN);
  }
}

function isExpiring(expires: number = 0) {
  const expireDate = new Date(expires * 1000);
  const now = new Date();
  return expireDate.getTime() - now.getTime() < ONE_HOUR;
}
