import * as Intl from "react-intl";
import { createGMTDate } from "msem-lib/es/services/utils";
import { widgetContextChanged } from "./customEvents";

const CART_ID = "cartId";
const CART_BY_RESORT = "cartIds";
const STAY_DATES = "stay";
const STAY_LODGING = "lodging";
const STAY_RESORT = "resort";
const STAY_PAXPLAN = "paxplan";

type Lodging = {
  name: string;
  position: [number, number];
  address?: string;
  code?: string;
};

type Paxplan = {
  adults: number;
  children: number;
  agesChildren: number[];
};

type Resort = {
  code: number;
  name: string;
  position: [number, number];
  search: [number, number];
  radius: number;
  zoom: number;
};

export function getStartDate(preview = false, isGescoOperator = false) {
  const startDate = createGMTDate();
  startDate.setHours(0, 0, 0, 0);
  if (preview || isGescoOperator) {
    startDate.setMonth(startDate.getMonth() - 6);
  }
  return startDate;
}

export function getEndDate(preview = false, isGescoOperator = false) {
  const endDate = createGMTDate();
  endDate.setHours(0, 0, 0, 0);
  const offset = preview || isGescoOperator ? 2 : 1;
  endDate.setFullYear(endDate.getFullYear() + offset);
  return endDate;
}

export function formatDate(intl: Intl.IntlShape, date: Date) {
  return intl.formatDate(date, { day: "numeric", month: "short" });
}

export function serializeDate(date: Date) {
  const yy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  return `${yy}-${mm}-${dd}`;
}

export function deserializeDates(dates: { from: string; to: string }) {
  const from = createGMTDate(dates.from);
  const to = createGMTDate(dates.to);
  return { from, to };
}

export function readCartByResorts() {
  const stored = window.sessionStorage.getItem(CART_BY_RESORT);
  if (!stored) return {};
  return JSON.parse(stored) ?? {};
}

export function writeCartByResorts(cartByResorts: Record<number, string>) {
  window.sessionStorage.setItem(CART_BY_RESORT, JSON.stringify(cartByResorts));
}

export function readCartId() {
  const stored = window.sessionStorage.getItem(CART_ID);
  if (!stored) return null;
  return JSON.parse(stored);
}

export function removeCartId(resort: number) {
  const cartId = window.sessionStorage.getItem(CART_ID);
  if (cartId) {
    window.sessionStorage.removeItem(CART_ID);
    widgetContextChanged(CART_ID, undefined);
  }
  const cartByResorts = readCartByResorts();
  cartByResorts[resort] = undefined;
  writeCartByResorts(cartByResorts);
}

export function syncCartId(resort: number, prevResort: number) {
  const cartByResorts = readCartByResorts();
  const cartId = readCartId();

  if (prevResort && cartId) {
    cartByResorts[prevResort] = { cartId };
    writeCartByResorts(cartByResorts);
  }
  if (resort !== undefined) {
    const newCartId = cartByResorts[resort];
    if (newCartId) {
      window.sessionStorage.setItem(CART_ID, JSON.stringify(newCartId.cartId));
      widgetContextChanged(CART_ID, newCartId.cartId);
      return newCartId.cartId;
    } else {
      const storedCartId = window.sessionStorage.getItem(CART_ID);
      if (storedCartId) {
        window.sessionStorage.removeItem(CART_ID);
        widgetContextChanged(CART_ID, undefined);
      }
      return undefined;
    }
  }
}

export function readDates() {
  const stored = window.sessionStorage.getItem(STAY_DATES);
  if (!stored) return null;
  const parsed = JSON.parse(stored);
  return deserializeDates(parsed) as { from: Date; to: Date };
}

export function writeDates(dates: { from: Date; to: Date }, resort: number, shouldRemoveCartId: boolean = true) {
  if (!dates) return;
  const from = serializeDate(dates.from);
  const to = serializeDate(dates.to);
  const stay = { from, to };
  window.sessionStorage.setItem(STAY_DATES, JSON.stringify(stay));
  widgetContextChanged(STAY_DATES, stay);
  if (shouldRemoveCartId) removeCartId(resort);
}

export function readLodging() {
  const stored = window.sessionStorage.getItem(STAY_LODGING);
  if (!stored) return null;
  return JSON.parse(stored) as Lodging;
}

export function writeLodging(lodging: Lodging) {
  if (!lodging) return;
  window.sessionStorage.setItem(STAY_LODGING, JSON.stringify(lodging));
  widgetContextChanged(STAY_LODGING, lodging);
}

export function removeLodging() {
  const storedLodging = window.sessionStorage.getItem(STAY_LODGING);
  if (storedLodging) {
    window.sessionStorage.removeItem(STAY_LODGING);
    widgetContextChanged(STAY_LODGING, undefined);
  }
}

export function stripHTML(html: string) {
  return html.replace(/<[^>]*>?/gm, "");
}

export function readPaxplan() {
  const stored = window.sessionStorage.getItem(STAY_PAXPLAN);
  if (!stored) return null;
  return JSON.parse(stored) as Paxplan;
}

export function writePaxplan(paxplan: Paxplan) {
  if (!paxplan) return;
  window.sessionStorage.setItem(STAY_PAXPLAN, JSON.stringify(paxplan));
  widgetContextChanged(STAY_PAXPLAN, paxplan);
}

export function removePaxplan() {
  const paxplan = window.sessionStorage.getItem(STAY_PAXPLAN);
  if (paxplan) {
    window.sessionStorage.removeItem(STAY_PAXPLAN);
    widgetContextChanged(STAY_PAXPLAN, undefined);
  }
}

export function readResort() {
  const stored = window.sessionStorage.getItem(STAY_RESORT);
  if (!stored) return null;
  return JSON.parse(stored) as Resort;
}

export function writeResort(resort: Resort) {
  if (!resort) return;
  window.sessionStorage.setItem(STAY_RESORT, JSON.stringify(resort));
  widgetContextChanged(STAY_RESORT, resort);
}

export function paxplansEqual(a: Paxplan, b: Paxplan) {
  return a.adults === b.adults && a.children === b.children && arraysEqual(a.agesChildren, b.agesChildren);
}

function arraysEqual(a: unknown[], b: unknown[]) {
  return a.length === b.length && a.every((value, index) => value === b[index]);
}
