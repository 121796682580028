import * as React from "react";
import classnames from "classnames";
import CloseIcon from "msem-lib/es/icons/close";
import ChevronLeftIcon from "msem-icons/es/chevron-left";
import { target } from "msem-lib/es/services/target";
import css from "./widget-titlebar.module.css";
import useWidgetContext from "msem-lib/es/widget/widget-context";
import configuration from "../../services/config";

const baseUrl = configuration().logoUrl;

type Props = {
  channel: string;
  close?: () => void;
  goBack?: () => void;
  noTitlebar?: boolean;
  onStartDrag: (x: number, y: number) => void;
  onDrag: (e: MouseEvent) => void;
  environments: string[];
  environmentChanged: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  lang: string;
};

function writeLogoUrl(channel: string, lang: string) {
  return `${baseUrl}/${lang}/${channel}`;
}

export default function WidgetTitlebar({
  channel,
  close,
  goBack,
  noTitlebar,
  onStartDrag,
  onDrag,
  environments,
  environmentChanged,
  lang,
}: Props) {
  const titleBarRef = React.useRef<HTMLDivElement>(null);
  const { options } = useWidgetContext();

  const onMouseDown = (e: React.MouseEvent) => {
    if (!options.draggable || !titleBarRef.current) return;
    const x = e.clientX;
    const y = e.clientY;
    onStartDrag(x, y);
    document.addEventListener("mousemove", onDrag);
    document.addEventListener("mouseup", onMouseUp);
    titleBarRef.current.style.cursor = "move";
  };

  const onMouseUp = (e: MouseEvent) => {
    if (!options.draggable || !titleBarRef.current) return;
    document.removeEventListener("mouseup", onMouseUp);
    document.removeEventListener("mousemove", onDrag);
    titleBarRef.current.style.cursor = "inherit";
  };

  const backClicked = () => goBack && goBack();
  const barClasses = classnames(css.titlebar, {
    [css.noTitlebar]: noTitlebar,
  });
  const selectedEnv = target();
  const multiEnvs = environments.length > 1;

  return (
    <div ref={titleBarRef} className={barClasses} onMouseDown={onMouseDown}>
      {goBack && <ChevronLeftIcon className={css.icon} onClick={backClicked} />}
      {!noTitlebar && (
        <>
          <img
            className={css.logo}
            src={writeLogoUrl(channel, lang)}
            height={38}
            alt={`${channel}-logo`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = writeLogoUrl(channel, "fr");
            }}
          />
          <div className={css.right}>
            {multiEnvs && (
              <select className={css.envSelector} onChange={environmentChanged} value={selectedEnv}>
                {environments.map((env) => (
                  <option key={env} value={env}>
                    {env}
                  </option>
                ))}
              </select>
            )}
            {!noTitlebar && <CloseIcon className={css.icon} onClick={close} />}
          </div>
        </>
      )}
    </div>
  );
}
