import * as React from "react";
import Button from "msem-ui/es/button";
import css from "./widget-error.module.css";

type Props = {
  children: React.ReactNode;
};

type State = {
  error: Error | null;
};

export default class ErrorBoundary extends React.Component<Props> {
  state: State = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch() {
    // TODO : Report error server-side (post : error.message)
    // const { error } = this.state;
    // console.log(error?.name, error?.message, error?.stack);
  }

  tryAgain = () => {
    this.setState({ error: null });
  };

  render() {
    const { error } = this.state;
    return (
      <>
        {error !== null && (
          <div className={css.error}>
            <div className={css.alert}>
              <div className={css.alertTitle}>Une erreur s'est produite !</div>
              <div className={css.alertSubtitle}>
                C'est embarrassant...
                <span role="img" aria-label="">
                  😔
                </span>
              </div>
              <Button className={css.button} onClick={this.tryAgain} size="S">
                Réessayer
              </Button>
            </div>
            <div className={css.report}>
              <div className={css.title}>
                <span className={css.prefix}>Erreur : </span>
                <span className={css.message}>{error.message}</span>
              </div>
            </div>
          </div>
        )}
        {error === null && this.props.children}
      </>
    );
  }
}
