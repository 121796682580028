import * as React from "react";
import * as Intl from "react-intl";
import classnames from "classnames";
import Button from "msem-lib/es/components/button";
import IntegerPicker from "msem-lib/es/components/integer-picker";
import SelectField from "msem-lib/es/components/select-field";
import css from "./pax-plan-form.module.css";

const Ages = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];

interface PaxPlanFormProps {
  onValidate: (pax: { adults: number; children: number; agesChildren: number[] }) => () => void;
  adults?: number;
  children?: number;
  agesChildren?: number[];
  compact?: boolean;
}

const PaxPlanForm = ({ onValidate, adults, children, agesChildren, compact = false }: PaxPlanFormProps) => {
  const intl = Intl.useIntl();
  const [selectedAdults, setAdults] = React.useState(adults || 0);
  const [selectedChildren, setChildren] = React.useState(children || 0);
  const [selectedChildrenAges, setChildrenAges] = React.useState(agesChildren || []);

  const changeAdults = (val: number) => {
    setAdults(val);
  };

  const changeChildren = (val: number) => {
    const oldVal = selectedChildren;
    const ages =
      val > oldVal ? [...selectedChildrenAges, 12] : selectedChildrenAges.slice(0, selectedChildrenAges.length - 1);
    setChildren(val);
    setChildrenAges(ages);
  };

  const changeAgeChildren = (index: number) => (val: string | number) => {
    const value = parseInt(val as string);
    const ages = [...selectedChildrenAges.slice(0, index), value, ...selectedChildrenAges.slice(index + 1)];
    setChildrenAges(ages);
  };

  return (
    <>
      <div className={classnames(css.paxPlan, { [css.compact]: compact })}>
        <div className={css.content}>
          <div className={css.adults}>
            <IntegerPicker min={0} max={20} value={selectedAdults} onChange={changeAdults} />
            <div className={css.label}>{intl.formatMessage({ id: "paxPlanFormAdults" })}</div>
          </div>
          <div>
            <div className={css.children}>
              <IntegerPicker min={0} max={9} value={selectedChildren} onChange={changeChildren} />
              <div className={css.label}>{intl.formatMessage({ id: "paxPlanFormChildren" })}</div>
            </div>
            {selectedChildren > 0 ? (
              <div className={css.childrenSuffix}>{intl.formatMessage({ id: "paxPlanFormChildrenSuffix" })}</div>
            ) : null}
            {selectedChildrenAges.map((age: number, index: number) => {
              return (
                <div key={index} className={css.ageRow}>
                  <div className={css.ageRowLabel}>
                    {intl.formatMessage({ id: "paxPlanFormChildrenCount" }, { count: index + 1 })}
                  </div>
                  <div className={css.select}>
                    <SelectField
                      value={age}
                      onChange={changeAgeChildren(index)}
                      label={""}
                      noLabelSpaceReservation
                      inline={false}
                    >
                      {Ages.map((option, idx) => (
                        <option key={idx} value={option}>
                          {intl.formatMessage({ id: "paxPlanFormChildrenAge" }, { count: option })}
                        </option>
                      ))}
                    </SelectField>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Button
        tag="a"
        onClick={onValidate({ adults: selectedAdults, children: selectedChildren, agesChildren: selectedChildrenAges })}
        contained
      >
        {intl.formatMessage({ id: "validate" })}
      </Button>
    </>
  );
};

export default PaxPlanForm;
