type Options = {
  switchSummerDate: string;
  switchWinterDate: string;
  facet: number;
};

export function computeFacetBySwitchDate(options: Options, stayStartDate?: string) {
  const { switchSummerDate, switchWinterDate } = options;
  if (!switchSummerDate || !switchWinterDate) return undefined;
  const dateToCompare = stayStartDate ? new Date(stayStartDate) : new Date();
  const thisYear = dateToCompare.getFullYear();
  const thisSummerDate = new Date(new Date(switchSummerDate).setFullYear(thisYear));
  const thisWinterDate = new Date(new Date(switchWinterDate).setFullYear(thisYear));
  return dateToCompare >= thisSummerDate && dateToCompare < thisWinterDate ? 1 : 0;
}

export function overloadFacetOption(options: Options, stayStartDate?: string) {
  const computedFacet = computeFacetBySwitchDate(options, stayStartDate);
  if (options.facet !== computedFacet) {
    options.facet = computedFacet !== undefined ? computedFacet : options.facet || 0;
  }
}
