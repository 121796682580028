import * as React from "react";
import * as ReactDOM from "react-dom";
import classnames from "classnames";
import Loader from "msem-lib/es/components/loader";
import css from "./dialog.module.css";

type Props = {
  scrollableRef: React.RefObject<HTMLDivElement>;
  opened: boolean;
  children: React.ReactNode;
  maxWidth?: number;
  grounded?: boolean;
  close: () => void;
};

const dialogId = "msem-widget-dialog";

export default function Dialog({ scrollableRef, opened, children, maxWidth, grounded, close }: Props) {
  const container = getContainer();
  const backdropRef = React.useRef<HTMLDivElement>(null);
  const backdropClasses = classnames(css.backdrop, {
    [css.opened]: opened,
    [css.grounded]: grounded,
  });
  const dialogClasses = classnames(css.dialog, {
    [css.opened]: opened,
    [css.grounded]: grounded,
  });

  const backdropClicked = (e: React.MouseEvent) => {
    if (e.target === backdropRef.current) {
      close();
    }
  };

  const keyPressed = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      close();
    }
  };

  return ReactDOM.createPortal(
    <div ref={backdropRef} className={backdropClasses} onClick={backdropClicked} onKeyUp={keyPressed}>
      <div className={dialogClasses} style={{ maxWidth }}>
        <div ref={scrollableRef} className={css.scrollable}>
          <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
        </div>
      </div>
    </div>,
    container
  );
}

function getContainer() {
  let container = document.getElementById(dialogId);
  if (!container) {
    container = document.createElement("div");
    container.id = dialogId;
    document.body.appendChild(container);
  }
  return container;
}
