import * as React from "react";
import classnames from "classnames";
import css from "./select-field.module.css";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const SelectField = ({
  value,
  error,
  autoComplete,
  label,
  noLabelSpaceReservation = false,
  disabled,
  inline,
  touched,
  onChange,
  children,
  forceTouch
}) => {
  const [focused, setFocused] = React.useState(false);
  const input = React.useRef(null);
  const [wasTouched, setWasTouched] = React.useState(!!touched ? touched : false);
  const clicked = () => {
    if (input.current) input.current.focus();
  };
  const blured = () => {
    setWasTouched(true);
    setFocused(false);
  };
  React.useEffect(() => {
    setWasTouched(touched || forceTouch ? true : false);
  }, [touched, forceTouch]);
  const changed = e => onChange(e.target.value);
  const labelClasses = classnames(css.label, {
    [css.labelFloating]: !inline,
    [css.labelHidden]: inline && value,
    [css.labelFocused]: focused
  });
  const selectClasses = classnames(css.select, {
    [css.selectInlined]: inline,
    [css.noLabel]: noLabelSpaceReservation
  });
  const inputClasses = classnames(css.input, {
    [css.inputDisabled]: disabled,
    [css.inputFocused]: focused,
    [css.noLabel]: noLabelSpaceReservation
  });
  const errorMessage = error || "";
  return _jsxs("div", {
    className: selectClasses,
    onClick: clicked,
    children: [_jsx("select", {
      disabled: disabled,
      autoComplete: autoComplete,
      className: inputClasses,
      value: value,
      ref: input,
      onChange: changed,
      onFocus: () => setFocused(true),
      onBlur: blured,
      children: children
    }), _jsx("label", {
      className: labelClasses,
      children: label
    }), _jsx("span", {
      className: css.error,
      children: touched === undefined ? errorMessage : wasTouched ? errorMessage : ""
    })]
  });
};
export default SelectField;