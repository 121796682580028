import * as React from "react";
import classnames from "classnames";
import css from "./button.module.css";
const Button = ({
  tag = "button",
  type = "button",
  className,
  disabled = false,
  contained = false,
  focus = false,
  onClick,
  children
}) => {
  const buttonRef = React.useRef(null);
  const classes = classnames(css.button, className, {
    [css.contained]: contained
  });
  const clicked = e => {
    if (disabled) return;
    if (onClick) onClick(e);
  };
  React.useEffect(() => {
    if (focus && buttonRef?.current) buttonRef.current.focus();
  }, [focus, buttonRef]);
  return React.createElement(tag, {
    type,
    className: classes,
    disabled,
    onClick: clicked,
    ref: buttonRef,
    tabIndex: 0,
    children: React.createElement("span", {
      className: css.label,
      children
    })
  });
};
export default Button;