import * as React from "react";
const useWidth = ref => {
  const [width, setWidth] = React.useState(ref.current?.offsetWidth ?? Number.MAX_VALUE);
  React.useLayoutEffect(() => {
    const resized = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      } else {
        requestAnimationFrame(resized);
      }
    };
    resized();
    window.addEventListener("resize", resized, {
      passive: true
    });
    return () => window.removeEventListener("resize", resized);
  });
  return width;
};
export default useWidth;