import * as React from "react";
import classnames from "classnames";
import * as Intl from "react-intl";
import Button from "msem-ui/es/button";
import { createGMTDate } from "msem-lib/es/services/utils";
import * as Stay from "../../services/stay";
import WarnIcon from "msem-lib/es/icons/warn";
import css from "./date-picker.module.css";

type MonthDates = {
  month: number;
  year: number;
  dates: Date[];
};

type MonthWeeks = {
  month: number;
  year: number;
  weeks: Date[][];
};

type Months<T> = {
  [key: string]: T;
};

export type SelectionChangedEvent = {
  start: Date;
  stop: Date;
};

type Selection = {
  start?: Date;
  stop?: Date;
};

type MonthProps = {
  month: string;
  months: Months<MonthWeeks>;
  children: React.ReactNode[];
};

type WeekProps = {
  weekIndex: number;
  children: React.ReactNode[];
};

type StayDates = {
  from?: Date;
  to?: Date;
};

type DatePickerProps = {
  scrollableRef?: React.RefObject<HTMLDivElement>;
  selected?: Selection;
  selectionChanged: ({ start, stop }: { start: Date; stop: Date }) => void;
  close: () => void;
  rangePicker?: boolean;
  from: Date;
  to: Date;
  extendableStay?: boolean;
  presetedDates?: StayDates;
  B2B?: boolean;
  isGescoOperator?: boolean;
};

export default function DatePicker({
  scrollableRef,
  selected = {},
  selectionChanged,
  close,
  rangePicker,
  from,
  to,
  extendableStay,
  presetedDates,
  B2B = false,
  isGescoOperator = false,
}: DatePickerProps) {
  const intl = Intl.useIntl();
  const [selection, setSelection] = React.useState(selected);
  const months = React.useMemo(() => buildMonths(from, to), [from, to]);
  const dayRef = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    const scrollable = scrollableRef?.current;
    const scrollTo = dayRef?.current;
    if (scrollable && scrollTo) {
      const monthElement = scrollTo?.parentElement?.parentElement;
      if (monthElement) {
        scrollable.scrollTop = monthElement.offsetTop - 60;
      }
    }
  }, [scrollableRef]);

  const changeSelection = (start: Date, stop?: Date) => {
    setSelection({ start, stop });
  };

  const rangeClicked = (day: Date) => {
    const { start, stop } = selection;
    if (!start || (start && stop)) changeSelection(day);
    if (start && !stop) {
      start > day ? changeSelection(day, start) : start === day ? changeSelection(day) : changeSelection(start, day);
    }
  };

  const clicked = (day: Date) => () => {
    rangePicker ? rangeClicked(day) : changeSelection(day);
  };

  const validated = () => {
    const { start, stop } = selection;
    if (start && !isEqual(selected, selection)) {
      selectionChanged({ start, stop: stop || plusOneWeek(start) });
    }
    close();
  };

  const isExtendableStayValid = () => {
    if (selection.start === undefined) return false;

    if (extendableStay && presetedDates && presetedDates.from && presetedDates.to) {
      const validStart = formatDate(selection.start) <= formatDate(presetedDates.from);
      if (!validStart) return false;
      const stop = formatDate(selection.stop || plusOneWeek(selection.start));
      const validStop = stop >= formatDate(presetedDates.to);
      return validStop;
    }

    return true;
  };

  const shouldDisableButton = () => {
    if (selection.start === undefined) return true;
    return isExtendableStayValid() ? false : true;
  };

  const warning =
    Stay.readDates() && Stay.readCartId()
      ? intl.formatMessage({ id: isGescoOperator ? "warning_gesco_operator" : "warning" })
      : undefined;

  const today = new Date();
  const warningDates =
    isGescoOperator && selection.start && selection.start < today
      ? intl.formatMessage({
          id:
            (selection.stop || selection.start) < today
              ? "warning_gesco_operator_past"
              : "warning_gesco_operator_from_past",
        })
      : undefined;

  return (
    <>
      <div className={css.stickyHeader}>
        {(warning || warningDates) && (
          <div className={css.warning}>
            <div className={css.warningSign}>
              <WarnIcon />
            </div>
            <span className={css.warningLabel}>
              {warning}
              {warning && warningDates && <br />}
              {warningDates}
            </span>
          </div>
        )}
        <WeekDays />
      </div>

      <div className={css.calendar}>
        {Object.keys(months).map((month) => (
          <Month key={month} month={month} months={months}>
            {months[month].weeks.map((week: Date[], weekIndex: number) => (
              <Week key={weekIndex} weekIndex={weekIndex}>
                {fillWeek(week).map((day, dayIndex) => {
                  const isStart = isStartDay(day, selection);
                  const start = selection.start;
                  const ref = isStart || (!start && isToday(day)) ? dayRef : undefined;
                  const liClasses = classnames(css.day, {
                    [css.selected]: isSelected(day, selection),
                    [css.stop]: isStopDay(day, selection),
                    [css.start]: isStart,
                  });
                  return day ? (
                    <li key={dayIndex} className={liClasses} ref={ref} onClick={clicked(day)}>
                      {day.getDate()}
                    </li>
                  ) : (
                    <li key={dayIndex} className={css.day} />
                  );
                })}
              </Week>
            ))}
          </Month>
        ))}
        <div className={css.stayActions}>
          {selection.start && !isExtendableStayValid() && (
            <div className={css.stayMessage}>
              {intl.formatMessage(
                { id: "extendableStayError" },
                { from: formatDateForUser(presetedDates?.from), to: formatDateForUser(presetedDates?.to) }
              )}
            </div>
          )}
          <Button disabled={shouldDisableButton()} onClick={validated} className={css.button} size="S">
            {B2B
              ? intl.formatMessage({ id: "validateDatePickerB2B" })
              : intl.formatMessage({ id: "validateDatePicker" })}
          </Button>
        </div>
      </div>
    </>
  );
}

function WeekDays() {
  const intl = Intl.useIntl();
  return (
    <div className={css.header}>
      {Array.from({ length: 7 }).map((_, index) => {
        const date = createGMTDate("2020-01-13"); // FYI : A random monday
        date.setDate(date.getDate() + index);
        return (
          <span key={index} className={css.weekDay}>
            {intl.formatDate(date, { weekday: "short" })}
          </span>
        );
      })}
    </div>
  );
}

function Month({ month, months, children }: MonthProps) {
  const intl = Intl.useIntl();
  const target = months[month];
  const monthNumber = (target.month + 1).toString().padStart(2, "0");
  const date = createGMTDate(`${target.year}-${monthNumber}-01`);
  const title = intl.formatDate(date, { month: "long", year: "numeric" });
  return (
    <React.Fragment key={month}>
      <span className={css.monthTitle}>{title}</span>
      <div className={css.month}>{children}</div>
    </React.Fragment>
  );
}

function Week({ weekIndex, children }: WeekProps) {
  return (
    <ul className={css.week} key={weekIndex}>
      {children}
    </ul>
  );
}

function isEqual(a: Selection, b: Selection) {
  return a?.start?.getTime() === b?.start?.getTime() && a?.stop?.getTime() === b?.stop?.getTime();
}

function formatDate(date: Date) {
  const yy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  return `${yy}-${mm}-${dd}`;
}

function formatDateForUser(date?: Date) {
  if (!date) return;
  const yy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  return `${dd}/${mm}/${yy}`;
}

function plusOneWeek(day: Date) {
  const date = createGMTDate(day);
  date.setDate(day.getDate() + 7);
  return date;
}

function buildRange(from: Date, to: Date) {
  const range = [];
  const f = createGMTDate(from);
  while (f <= to) {
    const current = createGMTDate(f);
    range.push(current);
    f.setDate(f.getDate() + 1);
  }
  return range;
}

function buildWeeks(month: MonthDates): MonthWeeks {
  return {
    year: month.year,
    month: month.month,
    weeks: month.dates?.reduce((acc: Date[][], date) => {
      const count = acc.length;
      const day = date.getDay();
      return day === 1
        ? [...acc, [date]]
        : count === 0
        ? [...acc, [date]]
        : acc.map((week, index) => (index === count - 1 ? [...week, date] : week));
    }, []),
  };
}

function buildMonths(from: Date, to: Date): Months<MonthWeeks> {
  const range = buildRange(from, to);
  const months = range.reduce((acc: Months<MonthDates>, date) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    const key = `${year}-${month}`;
    return acc[key]
      ? { ...acc, [key]: { ...(acc[key] as MonthDates), dates: [...acc[key].dates, date] } }
      : { ...acc, [key]: { ...(acc[key] as MonthDates), month, year, dates: [date] } };
  }, {});

  return Object.keys(months).reduce((acc: Months<MonthWeeks>, key: string) => {
    return { ...acc, [key]: buildWeeks(months[key]) };
  }, {});
}

function fillWeek(week: Date[]) {
  if (week.length === 7) return week;
  const first = week[0];
  const firstDay = (first.getDay() + 6) % 7;
  const filled: (Date | null)[] = [...week];
  for (let i = 0; i < firstDay; i++) filled.unshift(null);
  for (let i = filled.length; i < 7; i++) filled.push(null);
  return filled;
}

function isSelected(day: Date | null, selection: Selection) {
  const { start, stop } = selection;
  if (!start || !day) return false;
  const ref = formatDate(day);
  return (!stop && ref === formatDate(start)) || (stop && ref >= formatDate(start) && ref <= formatDate(stop));
}

function isToday(day: Date | null) {
  return day && formatDate(day) === formatDate(createGMTDate(new Date()));
}

function isStartDay(day: Date | null, selection: Selection) {
  const { start } = selection;
  return day && start && formatDate(day) === formatDate(start);
}

function isStopDay(day: Date | null, selection: Selection) {
  const { start, stop } = selection;
  return day && stop
    ? formatDate(day) === formatDate(stop)
    : day && start
    ? formatDate(day) === formatDate(start)
    : false;
}
