export function isShown(modes, hideModes, value) {
  if (value === "insurance") {
    return hideModes === undefined || !hideModes.includes(value);
  }
  if (value === "stayLodging") {
    return hideModes === undefined || !hideModes.includes(value);
  }
  return (modes === undefined || modes.includes(value)) && (hideModes === undefined || !hideModes.includes(value));
}
export function getQueryStringValue(key) {
  const regexp = new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i");
  return decodeURIComponent(window.location.search.replace(regexp, "$1"));
}
export function createGMTDate(fromDate) {
  const date = fromDate ? new Date(fromDate) : new Date();
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  date.setHours(0);
  const newDay = date.getDate();
  const newMonth = date.getMonth();
  const newYear = date.getFullYear();
  if (date.getTimezoneOffset() < 0 && (newDay < day || newMonth < month || newYear < year)) {
    date.setDate(date.getDate() + 1);
  }
  return date;
}
export function isMobileDevice() {
  return navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i);
}
const STAY_DATES = "stay";
export function deserializeDates(dates) {
  const from = createGMTDate(dates.from);
  const to = createGMTDate(dates.to);
  return {
    from,
    to
  };
}
export function readStayDates() {
  const stored = window.sessionStorage.getItem(STAY_DATES);
  if (!stored) return null;
  const parsed = JSON.parse(stored);
  return deserializeDates(parsed);
}
export const formatPrice = (intl, price, options = {}, freeLabel) => {
  if (price === undefined) return;
  if (price === 0 && freeLabel) return freeLabel;
  return intl.formatNumber(price, {
    style: "currency",
    currency: "EUR",
    ...options
  });
};
export function partition(array, filter) {
  let pass = [],
    fail = [];
  array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
  return [pass, fail];
}